// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'HOME',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    tag: 'Home',
  },
  {
    title: 'PROJECTS',
    icon: <Iconify icon={'eos-icons:project-outlined'} {...ICON_SIZE} />,
    tag: 'Projects',
  },
  {
    title: 'CONTACT',
    icon: <Iconify icon={'material-symbols:call'} {...ICON_SIZE} />,
    tag: 'Contact',
  },
  {
    title: 'ABOUT',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    tag: 'About',
  },
];

export default menuConfig;
